import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'

import Link from 'components/common/Link'
import {Large} from 'components/common/Responsive'

import classes from './MobileApps.module.css'

export const MobileApps = ({t, apps}) => (
  <Large>
    {matches => (
      <div
        className={classes.apps}
        style={matches ? {justifyContent: 'space-between'} : {justifyContent: 'space-around'}}
      >
        {
          apps.map((app, i) => (
            <Link key={i} to={app.href} className={classes.app}>
              <Label t={t} free={app.free} />
              <Image src={app.image} />
              <Content name={app.name} platforms={app.platforms} />
            </Link>
          ))
        }
      </div>
    )}
  </Large>
)

MobileApps.propTypes = {
  t: PropTypes.func.isRequired,
  apps: PropTypes.array.isRequired
}

const Label = ({t, free}) => (
  <div className={classes.label}>
    {free ? t('fleerp.AppsPage.Label.free') : t('fleerp.AppsPage.Label.paid')}
  </div>
)

Label.propTypes = {
  t: PropTypes.func.isRequired,
  free: PropTypes.bool.isRequired
}

const Image = ({src}) => (
  <div className={classes.image}>
    <img src={src} style={{maxHeight: 160, marginBottom: 0}} />
  </div>
)

Image.propTypes = {
  src: PropTypes.string.isRequired
}

const Content = ({name, platforms}) => (
  <div>
    <div className={classes.contentName}>{name}</div>
    <div className={classes.contentPlatforms}>{platforms}</div>
  </div>
)

Content.propTypes = {
  name: PropTypes.string.isRequired,
  platforms: PropTypes.string.isRequired
}

export default withNamespaces()(MobileApps)
