import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import MobileApps from 'components/fleerp/MobileApps'

import smsgateway from './sms_gateway.png'
import fleerptracker from './fleerptracker.png'
import fleerpactivator from './fleerp_activator.png'

export const AppsPage = ({t, navigate, location}) => {
  const apps = [
    {
      name: 'Fleerp Tracker',
      href: '/',
      free: false,
      image: fleerptracker,
      platforms: 'Apple / Android'
    },
    {
      name: 'Fleerp Activator',
      href: '/',
      free: true,
      image: fleerpactivator,
      platforms: 'Apple / Android'
    },
    {
      name: 'SMS Gateway',
      href: '/',
      free: true,
      image: smsgateway,
      platforms: 'Apple / Android'
    }
  ]

  return (
    <Layout breadcrumb navigate={navigate} location={location}>
      <Helmet
        title={t('fleerp.AppsPage.meta.title')}
        meta={[
          {
            name: 'description',
            content: t('fleerp.AppsPage.meta.description')
          },
          {
            name: 'keywords',
            content: t('fleerp.AppsPage.meta.keywords')
          }
        ]}
      />
      <div>
        <div style={{fontSize: 35, marginTop: 30, textAlign: 'center'}}>{t('fleerp.AppsPage.title')}</div>
        <MobileApps apps={apps} />
      </div>
    </Layout>
  )
}

AppsPage.propTypes = {
  t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default withNamespaces()(AppsPage)
